import React from 'react'
import { Flex, Box, Text, BoxProps } from '@clear/design-system'

export function PillColumnInfoGraphic() {
  return (
    <Box
      display={['flex', 'none']}
      flexDirection="column"
      minWidth="4rem"
      id="VPILL"
    >
      <Flex
        width="4rem"
        height="calc(33% + 40px)"
        borderRadius="2rem"
        backgroundColor="brandBlueWash"
      />
      <Flex
        width="4rem"
        height="calc(33% + 40px)"
        borderRadius="2rem"
        mt="-4rem"
        backgroundColor="brandBackgroundBlue"
      />
      <Flex
        width="4rem"
        height="calc(33% + 60px)"
        borderRadius="2rem"
        mt="-4rem"
        backgroundColor="brandLightBlue"
      />
    </Box>
  )
}

export function InfoGraphicHeader({
  ...props
}: React.PropsWithChildren<BoxProps>) {
  return (
    <Text
      as="h3"
      display="flex"
      justifyContent={['flex-start', 'center']}
      alignItems="center"
      height={['auto', '4rem']}
      mb={[4, 5]}
      borderRadius="2rem"
      fontSize={3}
      color="neutralsTextBlack"
      data-testid="infographic-header"
      {...props}
    />
  )
}

export function InfoGraphicBody(props: React.PropsWithChildren<BoxProps>) {
  return (
    <Box
      fontSize={1}
      px={[0, 8]}
      my={2}
      mt={[0, 2]}
      textAlign={['left', 'center']}
      display="inline-block"
      color="neutralsTextGray"
      data-testid="infographic-body"
      {...props}
    />
  )
}

function InfoGraphic(props: React.PropsWithChildren<BoxProps>) {
  return (
    <Box
      width={['auto', 'calc(33% + 40px)']}
      my={[3, 0]}
      pl={[4, 0]}
      borderRadius="2rem"
      textAlign={['left', 'center']}
      {...props}
    />
  )
}

export default InfoGraphic
