export const tsaPrecheckMembershipPrice = 77.95

export const tsaPrecheckPromotionalPrice = Number(0.0).toFixed(2)

export const tsaPrecheckClearBundlePrice = 199

export const tsaPrecheckRenewMembershipPrice = 68.95

export const tsaPrecheckClearRenewBundlePrice = 199

export const firstYearOfClear = ` $${(
  tsaPrecheckClearBundlePrice - tsaPrecheckMembershipPrice
).toFixed(2)}`

export const precheckPerYearPrice = ` $${Math.ceil(
  tsaPrecheckMembershipPrice / 5
)} `

export const precheckRenewalPerYearPrice = ` $${Math.ceil(
  tsaPrecheckRenewMembershipPrice / 5
)} `

export const clearMembershipPrice = 199
export const clearPrecheckBundleValue =
  clearMembershipPrice + tsaPrecheckMembershipPrice

export const clearPrecheckRenewalBundleValue =
  clearMembershipPrice + tsaPrecheckRenewMembershipPrice

export const bundledSavings = Math.round(
  clearMembershipPrice -
    (tsaPrecheckClearBundlePrice - tsaPrecheckMembershipPrice)
)

export const stringifyPrice = (price: number) => {
  return ` $${price}`
}
