import React, { SetStateAction, ReactNode } from 'react'
import { Flex, Text, Button, SVG } from '@clear/design-system'
import { ColorType } from '@clear/design-system/lib/theme/colors'
import Link from 'next/link'

export interface ModalProps {
  header?: string
  body: string | ReactNode
  bodyNote?: string
  showModal: boolean
  setShowModal: React.Dispatch<SetStateAction<boolean>>
  canClose?: boolean
  successButtonText?: string
  closeButtonText?: string
  successLink?: string
  successClick?: () => void
  showSuccessButton?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export function Modal(props: ModalProps) {
  const {
    header,
    body,
    bodyNote,
    showModal,
    setShowModal,
    canClose,
    successButtonText = 'success',
    successLink,
    successClick,
    showSuccessButton = true,
  } = props

  const handleCloseModal = () => setShowModal(false)

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={canClose ? handleCloseModal : noop}
          >
            <Flex
              p={6}
              flexDirection="column"
              backgroundColor="neutralsWhite"
              width={['tablet', 'half']}
              onClick={(e) => e.stopPropagation()}
              mx={[6, null]}
            >
              {canClose && !showSuccessButton && (
                <div className="relative">
                  <SVG
                    name="x-circle"
                    color="neutralsTextGray"
                    size="22"
                    onClick={handleCloseModal}
                    className="cursor-pointer absolute -top-5 -right-5"
                  />
                </div>
              )}
              {/* header */}
              <Flex flexDirection="column">
                {header && (
                  <Text
                    variant="medium"
                    as="h3"
                    fontSize={5}
                    data-cy="modal-heading"
                  >
                    {header}
                  </Text>
                )}
                <Text as="p" mt={2} data-cy="modal-content">
                  {body}
                </Text>
                {bodyNote && (
                  <Text as="p" mt={2} data-cy="modal-content">
                    {bodyNote}
                  </Text>
                )}
              </Flex>
              {showSuccessButton && (
                <Flex justifyContent="flex-end" pt={6}>
                  {successLink ? (
                    <Link href={successLink} passHref>
                      <Button
                        as="a"
                        size="small"
                        data-cy="success-link"
                        backgroundColor={'dhsBlue' as ColorType}
                        onClick={successClick}
                        text={successButtonText}
                        textProps={{ variant: 'regular' }}
                      />
                    </Link>
                  ) : (
                    <Button
                      size="small"
                      data-cy="success-link"
                      backgroundColor={'dhsBlue' as ColorType}
                      onClick={successClick}
                      text={successButtonText}
                      textProps={{ variant: 'regular' }}
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25" />
        </>
      ) : null}
    </>
  )
}
