import React from 'react'
import NextLink from 'next/link'
import { Flex, Box, Text } from '@clear/design-system'
import InfoGraphic, {
  InfoGraphicHeader,
  InfoGraphicBody,
  PillColumnInfoGraphic,
} from './InfoGraphic'

const hideEnrollmentFLow =
  process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true'

const InfoGraphicGroup = () => {
  return (
    <Flex>
      <PillColumnInfoGraphic />
      <Flex
        as="section"
        flexDirection={['column', 'row']}
        justifyContent="center"
        alignItems={['flex-start']}
      >
        <InfoGraphic>
          <InfoGraphicHeader
            display={['none', 'flex']}
            backgroundColor="brandBlueWash"
          >
            Get started online
          </InfoGraphicHeader>
          <InfoGraphicHeader display={['flex', 'none']}>
            Get started online
          </InfoGraphicHeader>
          <InfoGraphicBody>
            Pick your plan above and fill out information online.
          </InfoGraphicBody>
        </InfoGraphic>
        <InfoGraphic ml={['0rem', '-4rem']}>
          <InfoGraphicHeader
            display={['none', 'flex']}
            backgroundColor="brandBackgroundBlue"
          >
            <Box ml="-3em">Finish at a CLEAR location</Box>
          </InfoGraphicHeader>
          <InfoGraphicHeader display={['flex', 'none']}>
            <Box>Finish at a CLEAR location</Box>
          </InfoGraphicHeader>
          <InfoGraphicBody>
            {hideEnrollmentFLow ? (
              <Text id="homepage-enrollment-locations">
                Find a CLEAR enrollment location
              </Text>
            ) : (
              <NextLink href="/locations">
                <Text
                  color="brandDarkBlue"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  id="homepage-enrollment-locations"
                >
                  Find a CLEAR enrollment location
                </Text>
              </NextLink>
            )}{' '}
            at an airport nearby and one of our friendly Ambassadors will help
            you complete your TSA&nbsp;PreCheck® application.
          </InfoGraphicBody>
        </InfoGraphic>
        <InfoGraphic ml={['0rem', '-4rem']}>
          <InfoGraphicHeader
            display={['none', 'flex']}
            backgroundColor="brandLightBlue"
          >
            You’re all set!
          </InfoGraphicHeader>
          <InfoGraphicHeader display={['flex', 'none']}>
            You’re all set!
          </InfoGraphicHeader>
          <InfoGraphicBody>
            For TSA&nbsp;PreCheck®, you can expect to receive notification in
            about 3-5 days though some can take up to 60 days. Once approved,
            add your KTN to airline reservations to receive the TSA PreCheck®
            indicator on your boarding pass. If you also enrolled in CLEAR Plus,
            you’ll be able to use it right away in designated CLEAR lanes.
          </InfoGraphicBody>
        </InfoGraphic>
      </Flex>
    </Flex>
  )
}

export default InfoGraphicGroup
