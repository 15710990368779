import { useRouter } from 'next/router'
import { useIdleTimer } from 'react-idle-timer'
import { useTokenVerification } from './useTokenVerification'
import { setAccessToken, setSessionId } from '@api/utils'

const FIFTEEN_MINUTES_IN_MILLIS = 1000 * 60 * 15

export const getInactivityTimeout = (
  INACTIVITY_TIMEOUT = FIFTEEN_MINUTES_IN_MILLIS
) => INACTIVITY_TIMEOUT

export function useSessionTimer(
  timeoutCallback?: () => void,
  disableTokenVerification?: boolean,
  inactivityTimeout?: number
) {
  const router = useRouter()

  useTokenVerification(disableTokenVerification)

  const sessionTimeoutHandler = async () => {
    timeoutCallback && timeoutCallback()
    // Sign out of cognito
    setAccessToken('')
    setSessionId('')
    await router.push(
      {
        pathname: router.route,
        query: { sessionTimeout: 'true' },
      },
      undefined,
      { shallow: true }
    )
  }

  const idleTimerFunctions = useIdleTimer({
    timeout: getInactivityTimeout(inactivityTimeout),
    onIdle: sessionTimeoutHandler,
    debounce: 500,
  })

  return {
    ...idleTimerFunctions,
    sessionTimeoutHandler,
  }
}
