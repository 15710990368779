import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getAccessToken, setAccessToken } from '@api/utils'

const beforeUnloadListener = (event: any) => {
  event.preventDefault()
  return (event.returnValue = 'Are you sure you want to exit?')
}

export function useTokenVerification(disabled = false) {
  const router = useRouter()

  useEffect(() => {
    if (!disabled) {
      try {
        const token = getAccessToken()
        if (token) {
          addEventListener('beforeunload', beforeUnloadListener, {
            capture: true,
          })
        }
      } catch (err) {
        console.log({ tokenError: err })
        setAccessToken('')
        router.push('/')
      }
      return () => {
        removeEventListener('beforeunload', beforeUnloadListener, {
          capture: true,
        })
      }
    }
  }, [router, disabled])
}
