import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, BoxProps } from '@clear/design-system'
import { Modal } from '@components/shared/Modal'
import useEnrollmentApi from '@hooks/useEnrollmentApi'
import { useSessionTimer } from '@hooks/useSessionTimer'

type PageContentProps = BoxProps
const PageContent: React.FC<PageContentProps> = ({ ...props }) => {
  const { clearState: clearEnrollmentState } = useEnrollmentApi()
  const router = useRouter()
  const sessionTimeout =
    (router && router.query && router.query.sessionTimeout) || ''
  const pathname = (router && router.pathname) || ''
  const [showSessionModal, setShowSessionModal] = useState(false)

  const successClickFunction = () => {
    router.push('/')
  }

  useEffect(() => {
    const shouldShowModal = sessionTimeout === 'true' && pathname !== '/'
    if (shouldShowModal) {
      setShowSessionModal(true)
    }
  }, [pathname, sessionTimeout])

  useSessionTimer(clearEnrollmentState)

  return (
    <Box>
      <Box
        px={3}
        width={['full', 'tablet', 'content']}
        mx="auto"
        mt={[6, 7]}
        mb={6}
        {...props}
      />
      <Modal
        showModal={showSessionModal}
        setShowModal={setShowSessionModal}
        header="Your session has expired"
        body="For your security, you’ll need to enter your information again."
        successButtonText="Continue"
        successClick={successClickFunction}
        canClose={false}
      />
    </Box>
  )
}

export default PageContent
