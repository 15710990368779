import React, { useEffect, useState, ReactNode } from 'react'

import router, { useRouter } from 'next/router'
import NextLink from 'next/link'

import { Box, Button, Flex, Text } from '@clear/design-system'
import {
  Banner,
  PlanCard,
  PlanCardList,
  PlanCardListItem,
} from '@components/storybook'
import PageContent from '@components/layout/PageContent'
import InfoGraphicGroup from '@components/shared/InfoGraphicGroup'
import { PlanTypes, useEnrollmentContext } from '@components/EnrollmentContext'
import {
  clearPrecheckBundleValue,
  firstYearOfClear,
  precheckPerYearPrice,
  precheckRenewalPerYearPrice,
  stringifyPrice,
  tsaPrecheckClearBundlePrice,
  tsaPrecheckClearRenewBundlePrice,
  tsaPrecheckMembershipPrice,
  tsaPrecheckRenewMembershipPrice,
} from '@utils/pricing'
import { Modal } from '@components/shared/Modal'

const planCardListProps: PlanCardListItem = {
  text: '',
  color: 'neutralsTextBlack',
}

const tsaPlanCardList: PlanCardList = [
  {
    ...planCardListProps,
    text: 'Dedicated screening lanes in over 200+ airports and with 90+ airlines',
  },
  {
    ...planCardListProps,
    text: '5-year membership',
  },
  {
    ...planCardListProps,
    text: 'Children 17 & under join you for free when booked on the same reservation and when TSA PreCheck® appears on the child’s boarding pass',
  },
  {
    ...planCardListProps,
    text: 'Faster moving lines',
  },
]

const clearPlanCardList: PlanCardList = [
  {
    ...planCardListProps,
    text: 'CLEAR Plus Lanes at 50+ airports',
  },
  {
    ...planCardListProps,
    text: 'Friendly CLEAR Ambassadors welcome you',
  },
  {
    ...planCardListProps,
    text: 'Free Mobile app with features like Home-to-Gate that suggests when to leave for the airport',
  },
  {
    ...planCardListProps,
    text: 'Children 17 & under can use the CLEAR Plus Lane with you for free',
  },
  {
    ...planCardListProps,
    text: 'Use your CLEAR membership for faster entry at stadiums, concert halls, and other venues nationwide**',
  },
]

const hideEnrollmentlFlow =
  process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true'

const HomePageBanner: React.FC = ({ children }) => {
  useEffect(() => {
    const scrollToElement = () => {
      const element = document.getElementById('renew')
      if (element) {
        element.scrollIntoView()
      }
    }
    if (router?.asPath?.endsWith('#renew')) {
      setTimeout(scrollToElement, 100)
    }
  }, [])

  return (
    <Banner
      height={['auto', '20rem', '25rem']}
      backgroundImage="/passengers.jpeg"
      heading="Apply for TSA&nbsp;PreCheck® through CLEAR"
      headingProps={{
        width: '25.5rem',
        fontSize: '2rem',
      }}
      gradientStart="30%"
      showGradient
    >
      <Flex
        flexDirection={['column', 'row']}
        alignSelf="flex-start"
        justifyContent="flex-start"
        mt={4}
      >
        {children}
      </Flex>
    </Banner>
  )
}

const Section1: React.FC<{
  handleBannerSelectPlan: (plan: string) => void
  showApplyModal: () => void
}> = ({ handleBannerSelectPlan, showApplyModal }) => {
  return (
    <Flex
      flexDirection="column"
      pb={12}
      background="linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 47.68%)"
    >
      <Flex
        flexDirection="column"
        alignSelf="center"
        width={['full']}
        maxWidth="90rem"
        mt={[6, 12]}
        px={[6, 6, 6, 6, 12]}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          mb={8}
          data-cy="feature-header"
        >
          <Text
            fontSize={[2, 3]}
            textAlign="center"
            color="neutralsTextGray"
            mb={[9, 12]}
          >
            CLEAR is pleased to offer a seamless way to apply for
            TSA&nbsp;PreCheck®. Simply walk up to any participating CLEAR
            enrollment location next time you fly and enroll in minutes — no
            appointment, no hassle. Now, bundle TSA&nbsp;PreCheck® and CLEAR
            Plus for the fastest way to your gate at the best value.
          </Text>
          <Text
            as="h2"
            variant="display"
            fontSize={[6, 7]}
            color="brandMidnightBlue"
            textAlign="center"
          >
            Meet the best of both worlds for travel
          </Text>
        </Flex>
        <Flex flexDirection={['column', 'row']} mb={5}>
          <PlanCard
            display="flex"
            alignItems="stretch"
            width={[1, 1 / 2]}
            mr={[0, 6]}
            mb={[8, 0]}
            backgroundColor="neutralsWhite"
            header={<img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />}
            subHeaderText="TSA&nbsp;PreCheck® keeps you moving through security with no need to remove shoes, laptops, 3-1-1 liquids, belts, or light jackets."
            list={tsaPlanCardList}
            headerProps={{
              height: '5rem',
            }}
          />
          <PlanCard
            display="flex"
            alignItems="stretch"
            width={[1, 1 / 2]}
            backgroundColor="neutralsWhite"
            header={
              <img
                src="/clear-plus-logo.svg"
                alt="clear-logo"
                style={{ width: '140px', marginTop: '.5rem' }}
              />
            }
            subHeaderText="The CLEAR Plus membership is a fast way to verify your identity at the security checkpoint. Step up to a CLEAR Plus pod to confirm you are you with your fingerprint or eyes."
            list={clearPlanCardList}
            headerProps={{
              height: '5rem',
            }}
            data-cy="tsa-only-card"
          />
        </Flex>
        <Flex flexDirection={['column', 'row']} mb={5}>
          <PlanCard
            backgroundColor="neutralsWhite"
            header={
              <Flex flexDirection="row" alignItems="center">
                <img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />
                <Text
                  display="inline-flex"
                  alignItems="center"
                  height="2.5rem"
                  px={4}
                  color="brandMidnightBlue"
                >
                  +
                </Text>
                <Flex alignItems="center" height="2.5rem">
                  <img
                    src="/clear-plus-logo.svg"
                    alt="clear-logo"
                    style={{ width: '140px', marginTop: '.5rem' }}
                  />
                </Flex>
              </Flex>
            }
            subHeaderText="When you enroll in TSA PreCheck® and CLEAR Plus, you get the best of both worlds. You’ll be able to verify your identity quickly at security checkpoints using the CLEAR Plus pod. Then, a friendly Ambassador will walk you to the front for TSA screening where you’ll experience a smoother screening process with TSA PreCheck® — no need to remove shoes, belts, 3-1-1 liquids, laptops, or light jackets. Bundling them is the fastest way to your gate!"
            headerProps={{
              height: '5rem',
            }}
            data-cy="tsa-only-card"
          />
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            text="Apply"
            id="homepage-apply-slowscroll-2"
            variant="primary"
            textProps={{ variant: 'regular' }}
            mb={[5, 0]}
            mr={[0, 5]}
            onClick={() => {
              hideEnrollmentlFlow
                ? showApplyModal()
                : handleBannerSelectPlan('enroll')
            }}
          />
          <Button
            text="Renew"
            variant="secondary"
            id="homepage-renew-slowscroll-2"
            textProps={{ variant: 'regular' }}
            onClick={() => handleBannerSelectPlan('renew')}
            mr={[0, 5]}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

const Section2: React.FC<{
  handleSelectPlan: (plan: string) => void
  showApplyModal: () => void
  setIsPlanBundled: (arg: boolean) => void
}> = ({ handleSelectPlan, showApplyModal, setIsPlanBundled }) => {
  return (
    <Flex id="enroll" flexDirection="column" pb={12}>
      <Flex
        alignItems="stretch"
        flexDirection="column"
        alignSelf="center"
        width={['full']}
        maxWidth="90rem"
        mt={[8, 12]}
        px={[6, 6, 6, 6, 12]}
      >
        <Text
          as="h2"
          variant="display"
          fontSize={[5, 6, 7]}
          color="brandMidnightBlue"
          textAlign="center"
          mb={4}
        >
          Let's talk enrollment
        </Text>
        <Text
          as="p"
          fontSize={[4]}
          textAlign="center"
          color="neutralsTextGray"
          mb={9}
        >
          CLEAR makes it easy to enroll, just pick your package below to get
          started.
        </Text>
        <Flex
          alignItems="stretch"
          flexDirection={['column', 'row']}
          justifyContent="center"
          mb={12}
        >
          <PlanCard
            display="flex"
            width={[1, 1 / 2]}
            mr={[0, 8]}
            mb={[8, 0]}
            header={<img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />}
            upperBody={
              <Text my={6} color="neutralsTextGray">
                Get a 5-year TSA&nbsp;PreCheck® membership for a one-time fee of
                {stringifyPrice(tsaPrecheckMembershipPrice)}. This equates to
                less than {precheckPerYearPrice} a year!
              </Text>
            }
            price={tsaPrecheckMembershipPrice}
            priceColor="neutralsTextBlack"
            buttons={[
              <Flex
                key="card-4"
                flexDirection="column"
                alignItems="center"
                mt="6.75rem"
              >
                <Button
                  text="Apply"
                  variant="primary"
                  id="homepage-apply-precheck-only"
                  color="neutralsWhite"
                  textProps={{ variant: 'regular' }}
                  onClick={() => {
                    if (hideEnrollmentlFlow) {
                      showApplyModal()
                    } else {
                      handleSelectPlan(PlanTypes.ENROLL)
                    }
                    setIsPlanBundled(false)
                  }}
                  data-cy="section-two-enroll-button"
                />
              </Flex>,
            ]}
            headerProps={{
              height: '5rem',
            }}
            data-cy="tsa-only-card"
          />
          <PlanCard
            width={[1, 1 / 2]}
            header={
              <Flex flexDirection="row" alignItems="center">
                <img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />
                <Text
                  display="inline-flex"
                  alignItems="center"
                  height="2.5rem"
                  px={4}
                  color="brandMidnightBlue"
                >
                  +
                </Text>
                <Flex alignItems="center" height="2.5rem">
                  <img
                    src="/clear-plus-logo.svg"
                    alt="clear-logo"
                    style={{ width: '140px', marginTop: '.5rem' }}
                  />
                </Flex>
              </Flex>
            }
            headerBg="brandBlueWash"
            upperBody={
              <Text my={6} color="neutralsTextGray">
                Now when you enroll in a CLEAR Plus membership, you can bundle
                it with TSA PreCheck® for the fastest way to your gate.
              </Text>
            }
            price={tsaPrecheckClearBundlePrice}
            priceColor="neutralsTextBlack"
            body={
              <Box
                width={[1, '80%']}
                alignSelf="center"
                fontSize={3}
                color="neutralsTextGray"
                mt={4}
              >
                {stringifyPrice(tsaPrecheckMembershipPrice)} for 5 years of
                TSA&nbsp;PreCheck® and {firstYearOfClear}{' '}
                <span className="line-through">
                  ${tsaPrecheckClearRenewBundlePrice}
                </span>{' '}
                for your first year of CLEAR Plus.
                <br />
                <Text as="strong" color="neutralsTextBlack">
                  {' '}
                  A {stringifyPrice(clearPrecheckBundleValue)} value.
                </Text>
              </Box>
            }
            buttons={[
              <Flex
                key="card-5"
                flexDirection="column"
                alignItems="center"
                mt={8}
              >
                <Button
                  text="Apply"
                  textProps={{ variant: 'regular' }}
                  id="homepage-apply-bundle"
                  variant="primary"
                  color="neutralsWhite"
                  onClick={() => {
                    if (hideEnrollmentlFlow) {
                      showApplyModal()
                    } else {
                      handleSelectPlan(PlanTypes.ENROLL)
                    }
                    setIsPlanBundled(true)
                  }}
                  data-cy="section-two-plan-card-bundle"
                />
                <Box fontSize={0} color="neutralsTextGray" mt={8}>
                  Terms apply.* Offer available to non-CLEAR Plus members.{' '}
                  {stringifyPrice(tsaPrecheckMembershipPrice)} will be charged
                  in-person for your TSA&nbsp;PreCheck® application.
                  {stringifyPrice(tsaPrecheckClearRenewBundlePrice)} will be
                  charged for a 1-year CLEAR Plus Membership. CLEAR Plus
                  Membership renews at{' '}
                  {stringifyPrice(tsaPrecheckClearRenewBundlePrice)}
                  /year. Must apply for both TSA&nbsp;PreCheck® through CLEAR
                  and CLEAR Plus within 7 days using the same email and must
                  opt-in for the bundle. Rebate of{' '}
                  {stringifyPrice(tsaPrecheckMembershipPrice)} will be issued on
                  your CLEAR Plus Membership when you complete both your CLEAR
                  Plus enrollment and TSA&nbsp;PreCheck® application.
                </Box>
              </Flex>,
            ]}
            headerProps={{
              height: '5rem',
            }}
          />
        </Flex>
        <Flex flexDirection="column" justifyContent="center" mb={12}>
          <Text
            as="h1"
            variant="display"
            mb={5}
            fontSize={[5, 6, 7]}
            color="brandMidnightBlue"
            textAlign="center"
          >
            How to apply for TSA&nbsp;PreCheck® through CLEAR
          </Text>
          <InfoGraphicGroup />
        </Flex>
        <Flex flexDirection="column">
          <Text
            as="h2"
            variant="display"
            fontSize={[5, 6, 7]}
            color="brandMidnightBlue"
            textAlign="center"
            mb={5}
          >
            What you will need
          </Text>
          <Text textAlign="center" color="neutralsTextGray">
            Before you get started, make sure to have your passport or another
            form of approved documentation from{' '}
            <NextLink href="/help#required-documents">
              <Text
                color="brandDarkBlue"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                id="homepage-required-documents"
              >
                this list
              </Text>
            </NextLink>{' '}
            ready so you can enroll quickly. Keep in mind you’ll also need to
            bring these same documents to the airport when you finish
            enrollment.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Section3: React.FC<{
  handleSelectPlan: (plan: string) => void
  setIsPlanBundled: (arg: boolean) => void
}> = ({ handleSelectPlan, setIsPlanBundled }) => {
  return (
    <Flex
      id="renew"
      flexDirection="column"
      pb={12}
      background="linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 102.07%)"
    >
      <Flex
        flexDirection="column"
        alignSelf="center"
        alignItems="stretch"
        width={['full']}
        maxWidth="90rem"
        mt={[8, 12]}
        px={[6, 6, 6, 6, 12]}
      >
        <Text
          as="h1"
          variant="display"
          fontSize={[5, 6, 7]}
          color="brandMidnightBlue"
          textAlign="center"
          mb={4}
        >
          Renew TSA&nbsp;PreCheck® through CLEAR
        </Text>
        <Text
          as="p"
          fontSize={[4]}
          textAlign="center"
          color="neutralsTextGray"
          mb={9}
        >
          Renewing your TSA&nbsp;PreCheck® membership is easy through CLEAR.
          Pick your package below to get started.
        </Text>
        <Flex
          alignItems="stretch"
          flexDirection={['column', 'row']}
          justifyContent="center"
        >
          <PlanCard
            display="flex"
            width={[1, 1 / 2]}
            mr={[0, 8]}
            mb={[8, 0]}
            backgroundColor="neutralsWhite"
            header={<img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />}
            upperBody={
              <Box color="neutralsTextGray">
                <Box my={6} fontSize={2}>
                  Renew your 5-year TSA&nbsp;PreCheck® membership. Less than{' '}
                  {precheckRenewalPerYearPrice}
                  per year for all benefits.
                </Box>
                <Box fontSize={2}>Online renewals starting at</Box>
              </Box>
            }
            price={tsaPrecheckRenewMembershipPrice}
            priceColor="neutralsTextBlack"
            buttons={[
              <Flex
                key="card-6"
                flexDirection="column"
                alignItems="center"
                mt={12}
              >
                <Box fontSize={2} color="neutralsTextGray" mt={-10} mb={9}>
                  Renew in-person for{' '}
                  {stringifyPrice(tsaPrecheckMembershipPrice)}
                </Box>
                <NextLink
                  key="card-3"
                  href="/renewal/eligibility/eligibility"
                  passHref
                >
                  <Button
                    mt={11}
                    text="Renew"
                    id="homepage-renew-precheck-only"
                    textProps={{ variant: 'regular' }}
                    onClick={() => {
                      handleSelectPlan(PlanTypes.RENEW)
                      setIsPlanBundled(false)
                    }}
                    variant="primary"
                    color="neutralsWhite"
                    data-cy="section-three-renew-button"
                  />
                </NextLink>
              </Flex>,
            ]}
            headerProps={{
              height: '5rem',
            }}
            data-cy="tsa-only-card"
          />
          <PlanCard
            width={[1, 1 / 2]}
            header={
              <Flex flexDirection="row" alignItems="center">
                <img src="/tsa-pre-card-logo.svg" alt="tsa-pre-logo" />
                <Text
                  display="inline-flex"
                  alignItems="center"
                  height="2.5rem"
                  px={4}
                  color="brandMidnightBlue"
                >
                  +
                </Text>
                <Flex alignItems="center" height="2.5rem">
                  <img
                    src="/clear-plus-logo.svg"
                    alt="clear-logo"
                    style={{ width: '140px', marginTop: '.5rem' }}
                  />
                </Flex>
              </Flex>
            }
            headerBg="brandBlueWash"
            upperBody={
              <Box color="neutralsTextGray">
                <Box fontSize={2} mt={6} mb={6}>
                  Now when you renew your TSA PreCheck® membership, you can
                  bundle it with a CLEAR Plus membership for the fastest way to
                  your gate.
                </Box>
              </Box>
            }
            price={tsaPrecheckClearRenewBundlePrice}
            priceColor="neutralsTextBlack"
            buttons={[
              <Flex
                key="card-7"
                flexDirection="column"
                alignItems="center"
                fontSize={3}
              >
                <Box
                  width={[1, '80%']}
                  color="neutralsTextGray"
                  mb={8}
                  fontSize={2}
                >
                  {stringifyPrice(tsaPrecheckRenewMembershipPrice)} for 5-year
                  online renewal of TSA&nbsp;PreCheck® and $130.05{' '}
                  <span className="line-through">
                    {stringifyPrice(tsaPrecheckClearRenewBundlePrice)}
                  </span>{' '}
                  for your first year of CLEAR Plus.
                  <Box mt={8}>
                    {stringifyPrice(tsaPrecheckMembershipPrice)} for in-person
                    renewals of TSA&nbsp;PreCheck® with your first year of CLEAR
                    Plus at {firstYearOfClear}{' '}
                    <span className="line-through">
                      {stringifyPrice(tsaPrecheckClearRenewBundlePrice)}
                    </span>
                  </Box>
                </Box>
                <NextLink
                  key="card-3"
                  href="/renewal/eligibility/eligibility"
                  passHref
                >
                  <Button
                    text="Renew &amp; Get CLEAR Plus"
                    variant="primary"
                    id="homepage-renew-bundle"
                    textProps={{ variant: 'regular' }}
                    color="neutralsWhite"
                    onClick={() => [
                      handleSelectPlan(PlanTypes.RENEW),
                      setIsPlanBundled(true),
                    ]}
                    data-cy="section-three-plan-card-bundle"
                  />
                </NextLink>
                <Box fontSize={0} color="neutralsTextGray" mt={8}>
                  Terms apply.* Offer available to non-CLEAR Plus members.
                  {stringifyPrice(tsaPrecheckRenewMembershipPrice)} will be
                  charged online for your renewal of TSA&nbsp;PreCheck®. If
                  completed in-person,
                  {stringifyPrice(tsaPrecheckMembershipPrice)} will be charged
                  in-person for your renewal of TSA&nbsp;PreCheck®.
                  {stringifyPrice(tsaPrecheckClearBundlePrice)} will be charged
                  for a 1-year CLEAR Plus Membership. CLEAR Plus Membership
                  renews at {stringifyPrice(tsaPrecheckClearRenewBundlePrice)}
                  /year. Must apply for both TSA&nbsp;PreCheck® through CLEAR
                  and CLEAR Plus within 7 days using the same email and must
                  opt-in for the bundle. Rebate of
                  {stringifyPrice(tsaPrecheckRenewMembershipPrice)} will be
                  issued on your CLEAR Plus Membership when you complete both
                  your CLEAR Plus enrollment and online TSA&nbsp;PreCheck®
                  renewal. Rebate of
                  {stringifyPrice(tsaPrecheckMembershipPrice)} will be issued on
                  your CLEAR Plus Membership when your complete both your CLEAR
                  Plus enrollment and in-person TSA&nbsp;PreCheck® renewal.
                </Box>
              </Flex>,
            ]}
            headerProps={{
              height: '5rem',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

const HomePage: React.FC = () => {
  const router = useRouter()
  const { setSelectedPlan, setAffiliate, setIsPlanBundled } =
    useEnrollmentContext()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalBody, setModalBody] = useState<string | ReactNode>(
    'Please note: We are accepting online renewals only. At this time, we are not accepting new enrollment applications or in-person renewals.'
  )

  const showApplyModal = () => {
    setModalBody(
      <Text variant="body" textAlign="center" color="neutralsTextGray">
        Please note: We are accepting online renewals only. At this time, we are
        not accepting new enrollment applications or in-person renewals.
      </Text>
    )
    setIsModalVisible(true)
  }
  const showModal = () => setIsModalVisible(true)
  const hideModal = () => setIsModalVisible(false)

  useEffect(() => {
    if (hideEnrollmentlFlow) showModal()
  }, [])

  const handleBannerSelectPlan = (type: string) => {
    const element = document.getElementById(type)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSelectPlan = async (plan: string) => {
    setSelectedPlan(plan)

    if (plan === PlanTypes.RENEW) {
      await router.push('/renewal/eligibility/eligibility')
    } else {
      await router.push(
        `/enroll/document-check${
          router.query.test === 'true' ? '?test=true' : ''
        }`
      )
    }
  }

  useEffect(() => {
    router.prefetch('enroll/document-check')
  }, [router])

  useEffect(() => {
    if (router.query.affiliate) {
      setAffiliate(router.query.affiliate as string)
    }
  }, [router, setAffiliate])

  return (
    <Flex flexDirection="column" id="HomePageWrapper">
      <HomePageBanner>
        <Button
          key="card-1"
          id="homepage-apply-slowscroll-1"
          text="Apply"
          variant="primary"
          textProps={{ variant: 'regular' }}
          mr={[0, 5]}
          mb={[5, 0]}
          onClick={() => {
            hideEnrollmentlFlow
              ? showApplyModal()
              : handleBannerSelectPlan('enroll')
          }}
          data-cy="home-banner-enroll-btn"
        />
        <Button
          text="Renew"
          variant="primary"
          id="homepage-renew-slowscroll-1"
          textProps={{ variant: 'regular' }}
          backgroundColor="neutralsWhite"
          onClick={() => handleBannerSelectPlan('renew')}
          data-cy="home-banner-renew-btn"
        />
      </HomePageBanner>
      <PageContent width="full" alignSelf="center" m={0} p={0}>
        <Section1
          handleBannerSelectPlan={handleBannerSelectPlan}
          showApplyModal={showApplyModal}
        />
        <Section2
          handleSelectPlan={handleSelectPlan}
          showApplyModal={showApplyModal}
          setIsPlanBundled={setIsPlanBundled}
        />
        <Section3
          handleSelectPlan={handleSelectPlan}
          setIsPlanBundled={setIsPlanBundled}
        />
        <Flex flexDirection="column" justifyContent="center" mb={8}>
          <Text fontSize={0} textAlign="center" color="neutralsTextGray">
            *CLEAR Plus is currently only available to U.S. citizens and legal
            permanent residents 18 and older with a valid government-issued
            photo ID.
          </Text>
          <Text fontSize={0} textAlign="center" color="neutralsTextGray">
            **This service is not associated with the TSA&nbsp;PreCheck®
            Application Program.
          </Text>
        </Flex>
        <Modal
          showModal={isModalVisible}
          setShowModal={setIsModalVisible}
          body={modalBody}
          canClose={true}
          successButtonText="Ok"
          successClick={() => hideModal()}
        />
      </PageContent>
    </Flex>
  )
}

export default HomePage
